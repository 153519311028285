import { getSubdomainFromHostname } from "@/modules/request/helpers/getSubdomainFromHostname"

export function getCareerExplorerHostFromHostname(hostname: string): string {
  const cleanedHostName = hostname.replace(/:[0-9]*/, "")
  const [subDomain] = getSubdomainFromHostname(cleanedHostName)
  let careerExplorerHost = (process.env.NEXT_PUBLIC_CAREER_EXPLORER_API_BASE_URL || "").replace(/https?:\/\//, "")

  if (subDomain) {
    careerExplorerHost = careerExplorerHost.replace("www", subDomain)
  }
  return careerExplorerHost
}
