import { getSubdomainFromHostname } from "@/modules/request/helpers/getSubdomainFromHostname"

export function getMonolithHostFromHostname(hostname: string): string {
  const cleanedHostName = hostname.replace(/:[0-9]*/, "")
  const [subDomain] = getSubdomainFromHostname(cleanedHostName)
  let monolithHost = (process.env.NEXT_PUBLIC_MONOLITH_API_BASE_URL || "").replace(/https?:\/\//, "")

  if (subDomain) {
    monolithHost = monolithHost.replace("www", subDomain)
  }

  return monolithHost
}
